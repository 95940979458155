import React from 'react'
import Layout from "../components/layout"
import A from '../components/a'
import Padded from '../components/padded'
import ImageFull from '../components/image-full';
import logoSvg from '../images/o-nas.svg';

const IndexPage = () => (
  <Layout fixedBottom={
    <ImageFull src={logoSvg} />
  }>
    <Padded>
      <p>
        Æsthetika Legín<br />
        The Sassy Satan<br />
        Punc Tajemnosti<br />
        Dead Slut<br />
      </p>
      <p>
        Kreativní diverze ze šuplat tvůrců a přátel <A href="https://www.breisky.cz/" target="_blank">grafického studia Breisky</A>. Kabinet kuriozit a podvratných parafernálií oddaných minimalistické typografii, s důrazem na neobvyklá spojení témat, materiálů i médií. Pro každého něco, pro všechny však radost z taktilních vjemů a vůně papíru, barev i knih. Chiméry umění a rebelie ve formě plakátů i drobných tisků, hravost dokreslovaček pro děti, hlubinný ponor do lingvistického smetiště prostřednictvím Odborného jazykového rádce ve věcech delikátních. Šuplíčky plné materiálu, který se jinam nevešel, nabízejí lehce dekadentní kultivaci ducha od kolébky až za oponu.
    </p>
      <p>
        desetdekadekadence@gmail.com<br />
        <A target="_blank" href="https://www.instagram.com/desetdekadekadence/">instagram_desetdekadekadence</A><br />
        <A target="_blank" href="https://www.facebook.com/desetdekadekadence">facebook_Deset Deka Dekadence</A><br />
      </p>
    </Padded>
  </Layout>
)

export default IndexPage
